import { DefaultTheme } from "styled-components";

interface MyTheme extends DefaultTheme {
  color: {
    neavyBlue: string,
    lightBlue: string,
    yellow: string,
    pink: string,

    turquoise: string,
    violet: string,
    red: string,
    white: string,
    error: string,
  },

  font: {
    main: string,
    title: string,
    inputs: string,
    errors: string,
    label: string,
  },

  fontSize: {
    small: string,
    mobileDesc: string,
    normal: string,
    mobileTitle: string,
    pageTitle: string,
    smallerTitle: string,
    title: string,
    big: string,
    errors: string,
    label: string,
  },
  fontWeight: {
    normal: string,
    bold: string,
  },
}

const myTheme: MyTheme = {
  color: {
    neavyBlue: "#2535C6;",
    lightBlue: "#9EFCFF",
    yellow: "#FFF9AC",
    pink: "#FFC7EB",

    turquoise: "#12BEE5",
    violet: "#C071E9",
    red: "#F24F5A",
    white: "#FFFFFF",
    error: "#F24F5A",
  },

  font: {
    main: "Poppins",
    title: "Roboto",
    inputs: "Open Sans",
    errors: "Open Sans",
    label: "Open Sanspx",
  },

  fontSize: {
    small: "12px",
    mobileDesc: "14px",
    normal: "16px",
    mobileTitle: "24px",
    pageTitle: "32px",
    smallerTitle: "40px",
    title: "48px",
    big: "72px",
    errors: "12px",
    label: "12px",
  },
  fontWeight: {
    normal: "400",
    bold: "700",
  },
};

export { myTheme };
